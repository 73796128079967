import React from 'react';
import clsx from 'clsx';
import { ProductOutput } from 'js/model/rainbow/venue/ProductOutput';
import { AccoladeOutput } from 'js/model/rainbow/browse-page/AccoladeOutput';
import gridStyles from 'assets/style/grid.module.css';
import { VenueAccolades } from './VenueAccolades/VenueAccolades';
import { ProductsUsed } from './ProductsUsed/ProductsUsed';

function getProductsAccoladesClasses(
  productCount: number,
  accoladeCount: number
): { products: string | string[]; accolades: string | string[] } {
  /* If we have only products, or only accolades, then use full width.
   * If we have both, there should be max 4 products in a row,
   * with space left at the end for accolades.
   */

  // default full width classes
  const classes: {
    products: string | string[];
    accolades: string | string[];
  } = {
    products: gridStyles['col-xs-12'],
    accolades: gridStyles['col-xs-12'],
  };

  if (productCount <= 3 && accoladeCount) {
    /* if < 4 products, split the 12 cols evenly and
     * alot accolades the remaining
     */

    // allow at min 3 cols
    const productCols = Math.max(productCount * 2, 3);

    classes.products = [
      gridStyles[`col-sm-${productCols}` as keyof typeof gridStyles],
      gridStyles['col-xs-12'],
    ];
    classes.accolades = [
      gridStyles[`col-sm-${12 - productCols}` as keyof typeof gridStyles],
      gridStyles['col-xs-12'],
    ];
  } else if (productCount > 3 && accoladeCount) {
    // otherwise allow 3/4 split
    classes.products = [gridStyles['col-sm-9'], gridStyles['col-xs-12']];
    classes.accolades = [gridStyles['col-sm-3'], gridStyles['col-xs-12']];
  }

  return classes;
}

interface Props {
  products: ProductOutput[];
  accolades: AccoladeOutput[];
  i18n: (key: string, count?: number | string) => string;
}

export function ProductsAccoladesSection(props: Props): React.ReactElement {
  const { products, accolades, i18n } = props;
  const classes = getProductsAccoladesClasses(
    products.length,
    accolades.length
  );

  return (
    <div className={gridStyles.row}>
      {!products.length ? null : (
        <div className={clsx(classes.products)}>
          <ProductsUsed products={products} fullWidth={!accolades.length} />
        </div>
      )}

      {!accolades.length ? null : (
        <div className={clsx(classes.accolades)}>
          <VenueAccolades accolades={accolades} i18n={i18n} />
        </div>
      )}
    </div>
  );
}
