import React, {
  Suspense,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  IconImage,
  Inline,
  Stack,
  Text,
  Viewport,
  useDevice,
  useModal,
  useViewport,
} from '@treatwell/ui';
import { EmployeePortfolioImage } from 'js/model/rainbow/ImageOutput';
import { Context } from 'js/components/LocaleWrapper';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import { getFragmentValue, removeHashParameter } from 'js/helpers/uri-util';
import { useTranslation } from 'react-i18next';
import { PortfolioImagePopup } from '../../PortfolioImagePopup/PortfolioImagePopup';
import { OurWorkImageButton } from './OurWorkImageButton';
import styles from './OurWorkExperiment.module.css';
import { getGroupedItems } from './get-group-items';

const OurWorkModal = lazy(() =>
  import(/* webpackChunkName: "OurWorkModal" */ './OurWorkModal')
);

export const OurWorkExperiment = ({
  images,
}: {
  images: EmployeePortfolioImage[];
}) => {
  const { pageData, i18n } = useContext(Context);
  const scrollableListRef = useRef<HTMLDivElement>(null);
  const [selectedImage, setSelectedImage] = useState<
    EmployeePortfolioImage | undefined
  >();
  const [shouldShowSeeAllButton, setShouldShowSeeAllButton] = useState(false);
  const { isMobile: isMobileDevice } = useDevice();
  const isMobile = useViewport({
    device: 'mobile',
    serverRender: isMobileDevice,
  });
  const { isOpen: isModalOpen, modalProps, openModal, closeModal } = useModal({
    preventDismiss: true,
  });
  const ourWorkLabels = i18n<CmsOurWork>('page.venue.our-work');
  const findImage = useCallback(
    (imageId: string) => {
      const image = images.find(image => image.id.toString() === imageId);

      if (image) {
        setSelectedImage(image);
      }
    },
    [images]
  );
  const onPopState = useCallback(() => {
    const imageId = getFragmentValue('portfolioImage');

    if (imageId) {
      findImage(imageId);
    } else if (getFragmentValue('portfolioImage') === undefined) {
      setSelectedImage(undefined);
    }
  }, [findImage]);
  const { t } = useTranslation();
  const seeAllButtonLabel = t('venuePage.ourWork.seeAllButton');

  useEffect(() => {
    const shouldShowSeeAllButton = () => {
      const scrollableList = scrollableListRef.current;

      if (
        scrollableList &&
        scrollableList.scrollWidth > scrollableList.clientWidth
      ) {
        setShouldShowSeeAllButton(true);
      } else {
        setShouldShowSeeAllButton(false);
      }
    };

    if (isMobileDevice) {
      shouldShowSeeAllButton();

      window.addEventListener('resize', shouldShowSeeAllButton);
    }

    return () => {
      if (isMobileDevice) {
        window.removeEventListener('resize', shouldShowSeeAllButton);
      }
    };
  }, [isMobile, isMobileDevice]);

  useEffect(() => {
    const imageId = getFragmentValue('portfolioImage');

    if (imageId) {
      findImage(imageId);
    }
  }, [findImage]);

  useEffect(() => {
    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [onPopState]);

  return (
    <>
      <Stack className={styles.root} space="md">
        <Viewport device={['mobile', 'tablet']} serverRender={isMobileDevice}>
          <Inline className={styles.titleWrapper} justify="between">
            <Text type="mdHeader" className={styles.title}>
              {ourWorkLabels.heading}
            </Text>
            {shouldShowSeeAllButton && (
              <Button
                className={styles.seeAllButton}
                size="sm"
                onClick={openModal}
              >
                {seeAllButtonLabel}
              </Button>
            )}
          </Inline>
          <div className={styles.scrollableList} ref={scrollableListRef}>
            <Inline className={styles.list} space="xs">
              {images.map((image, index) => (
                <OurWorkImageButton
                  key={image.id}
                  buttonClassName={styles.seeImageButton}
                  imageClassName={styles.image}
                  image={image}
                  isLazy={index > 11}
                  onClick={imageId => {
                    findImage(`${imageId}`);
                  }}
                />
              ))}
            </Inline>
          </div>
        </Viewport>
        <Viewport device="desktop" serverRender={!isMobileDevice}>
          <Stack space="md">
            <Text type="bodyHeavy">{ourWorkLabels.heading}</Text>
            <Box className={styles.box} paddingWide="md">
              <Stack align="end" space="md">
                {getGroupedItems(images.slice(0, 10), 5).map(
                  (group, groupIndex) => (
                    <div key={groupIndex} className={styles.grid}>
                      {group.map(image => (
                        <OurWorkImageButton
                          key={image.id}
                          buttonClassName={styles.seeImageButton}
                          imageClassName={styles.image}
                          image={image}
                          onClick={imageId => {
                            findImage(`${imageId}`);
                          }}
                        />
                      ))}
                    </div>
                  )
                )}
                {images.length > 10 && (
                  <Button
                    className={styles.seeAllButton}
                    size="lg"
                    icon={<IconImage size={24} />}
                    onClick={openModal}
                  >
                    {seeAllButtonLabel}
                  </Button>
                )}
              </Stack>
            </Box>
          </Stack>
        </Viewport>
      </Stack>
      {isModalOpen && (
        <Suspense>
          <OurWorkModal
            images={images}
            modalProps={modalProps}
            onSelectedImage={imageId => {
              findImage(`${imageId}`);
            }}
          />
        </Suspense>
      )}
      {selectedImage && (
        <PortfolioImagePopup
          pageData={pageData}
          images={images}
          initiallySelectedImage={selectedImage}
          isOurWorkModalOpen={isModalOpen}
          closeOurWorkModal={closeModal}
          onClose={() => {
            removeHashParameter(window.location, 'portfolioImage');
            setSelectedImage(undefined);
          }}
          cms={
            {
              ...ourWorkLabels,
              ...i18n('page.venue.head.review-text.short'),
              ...i18n('page.venue.vc-bar'),
              ...i18n('page.venue.menu.labels'),
            } as CmsOurWork
          }
        />
      )}
    </>
  );
};
