/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  24: [
    'M5.92 2.041c-.769.051-1.248.164-1.74.41A3.776 3.776 0 002.124 5.16C2.013 5.729 2 6.455 2 12c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245 1.102-.332 2.103-1.333 2.435-2.435.239-.794.245-.962.245-7.32 0-6.358-.006-6.526-.245-7.32-.331-1.099-1.29-2.058-2.435-2.434-.676-.222-.52-.217-6.98-.227-3.278-.005-6.167.005-6.42.022m12.572 2.05c.402.097.617.218.908.509.296.295.414.509.512.928.063.269.068.785.068 6.472s-.005 6.203-.068 6.472c-.098.419-.216.632-.512.929-.299.3-.58.45-.982.527-.408.077-12.428.077-12.836-.001a1.69 1.69 0 01-.982-.524c-.297-.299-.414-.513-.512-.931-.063-.269-.068-.785-.068-6.472s.005-6.203.068-6.472c.087-.373.206-.61.432-.861.363-.405.762-.576 1.46-.628.22-.016 3.064-.026 6.32-.021 5.343.006 5.947.014 6.192.073M13 6.65c-.197.118-.305.253-1.184 1.484a82.377 82.377 0 01-1.01 1.396c-.029.029-.222-.093-.644-.408-.754-.564-.827-.602-1.16-.601-.308.001-.523.087-.71.284-.132.138-3.084 5.502-3.218 5.845a.968.968 0 00.225 1.051c.322.323-.204.299 6.718.297 6.658-.002 6.308.01 6.602-.215.298-.227.452-.702.341-1.047-.05-.155-4.549-7.69-4.723-7.911-.142-.18-.45-.304-.759-.305-.224 0-.292.018-.478.13m1.855 5.056c.74 1.236 1.345 2.258 1.345 2.27 0 .013-1.915.024-4.257.024H7.687l.801-1.47c.44-.808.812-1.482.826-1.497.014-.015.301.178.637.428.662.495.81.562 1.165.526.414-.043.492-.121 1.439-1.442.697-.972.864-1.183.904-1.139.028.03.656 1.065 1.396 2.3',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconImage = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconImage.displayName = 'IconImage';
