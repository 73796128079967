import React from 'react';
import clsx from 'clsx';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { ProductOutput } from 'js/model/rainbow/venue/ProductOutput';
import gridStyles from 'assets/style/grid.module.css';
import { Context } from 'js/components/LocaleWrapper';
import styles from './ProductsUsed.module.css';

function getImageUri(image: ImageOutput): string | null {
  if (!image) {
    return null;
  }
  return image.uris[Object.keys(image.uris)[0]];
}

function getProductClasses(productCount: number, fullWidth: boolean): string {
  /* If fullwidth, retain standard grid classes.
   * If not fullWidth then we know that the component is sharing a row with accolades
   * If there are accolades, there should be max 4 products in a row
   */

  if (fullWidth) {
    return clsx(
      gridStyles['col-xs-6'],
      gridStyles['col-sm-4'],
      gridStyles['col-md-3'],
      gridStyles['col-lg-2']
    );
  }

  const maxCols = 12;
  return clsx(
    gridStyles['col-xs-6'],
    gridStyles[
      `col-sm-${productCount > 1 ? 6 : maxCols}` as keyof typeof gridStyles
    ],
    gridStyles[
      `col-md-${
        productCount <= 3 ? maxCols / productCount : 3
      }` as keyof typeof gridStyles
    ],
    gridStyles[
      `col-lg-${
        productCount <= 3 ? maxCols / productCount : 3
      }` as keyof typeof gridStyles
    ]
  );
}

function getProducts(
  products: ProductOutput[],
  fullWidth: boolean
): React.ReactNode[] | null {
  if (!products) {
    return null;
  }

  return products.map(product => {
    const { name } = product;
    const uri = getImageUri(product.image);
    const imageNode = uri ? (
      <img className={styles.logo} src={uri} alt={name} />
    ) : (
      <span className={styles.name}>{name}</span>
    );
    const classes = getProductClasses(products.length, fullWidth);

    return (
      <div key={product.name} className={classes}>
        <div className={styles.product} title={name}>
          {imageNode}
        </div>
      </div>
    );
  });
}

interface Props {
  products: ProductOutput[];
  fullWidth?: boolean;
}

export function ProductsUsed(props: Props): React.ReactElement {
  const { products, fullWidth = true } = props;

  const productsNodes = getProducts(products, fullWidth);

  return (
    <Context.Consumer>
      {value => (
        <div>
          <div className={styles.title}>
            {value.i18n('page.venue.section-titles.products-used')}
          </div>
          <div className={gridStyles.row}>{productsNodes}</div>
        </div>
      )}
    </Context.Consumer>
  );
}
