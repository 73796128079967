import React, { useContext, useState } from 'react';
import { Stack, Text } from '@treatwell/ui';
import clsx from 'clsx';
import { Context } from 'js/components/LocaleWrapper';
import { MenuGroup, VenueStatusType } from 'js/model/rainbow/venue/VenueOutput';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { ConditionalWrapper } from 'js/components/Utilities/ConditionalWrapper';
import gridStyles from 'assets/style/grid.module.css';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getSearchCriteria, hasSearchCriteria } from '../searchCriteria';
import {
  SUGGESTED_SERVICES_MENU_GROUP_ID,
  getGroupDiscountTypes,
  getServices,
} from '../createSuggestedServicesGroup';
import { createList } from '../createHighlightedServices';
import { MenuGroupItems } from '../MenuGroup/MenuGroupItems';
import { ShowMoreLink } from './ShowMoreLink';
import styles from './HighlightedServices.module.css';

const MIN_TO_SHOW = 5;
const MAX_TO_SHOW_SEARCH = 10;
const MAX_TO_SHOW_POPULAR = MIN_TO_SHOW;

const HighlightedServicesComponent = ({
  isChurned,
  isWidget,
  isSearch,
}: {
  isChurned: boolean;
  isWidget: boolean;
  isSearch: boolean;
}) => {
  const { pageData, i18n } = useContext(Context);
  const [showMore, setShowMore] = useState<boolean>(false);
  const { pageParameters, venue, channel } = pageData;
  const searchCriteria = getSearchCriteria(pageParameters);
  const menu = venue.venue.menu;
  const allServices: VenueMenuItemTypeOutput[] = getServices(
    menu.menuGroups,
    menu.highlights!
  );
  const services = createList(
    allServices,
    allServices.filter(service => service.highlights),
    searchCriteria,
    isChurned
  );
  const group = ({
    name: 'HighlightedServices',
    id: SUGGESTED_SERVICES_MENU_GROUP_ID,
    menuItems: services,
    priceRange: {
      yieldDiscountTypes: getGroupDiscountTypes(services),
    },
  } as unknown) as MenuGroup;
  const maxToShow = isSearch ? MAX_TO_SHOW_SEARCH : MAX_TO_SHOW_POPULAR;
  const itemsToShow = showMore ? maxToShow : MIN_TO_SHOW;
  const displayGroup = {
    ...group,
    menuItems: group.menuItems.slice(0, itemsToShow),
  };
  const moreToShow = Math.min(
    MIN_TO_SHOW,
    group.menuItems.length - displayGroup.menuItems.length
  );
  const isMore = isSearch && !showMore && moreToShow > 0;
  const title = isSearch
    ? i18n(
        `venue.menu.title.${isWidget ? 'matching-service' : 'matching-search'}`
      )
    : i18n('venue.menu.title.popular-services');
  const trackingGroupType = isSearch ? 'matching_services' : 'popular_services';
  const isOurWorkExperimentActive =
    useFeatureValue('ff-web-venue-our_work_above_menu', 'none') === 'variant-1';

  return (
    <ConditionalWrapper
      condition={!isOurWorkExperimentActive}
      render={children => (
        <div
          className={clsx({
            [styles.highlightedServicesWidgetWrapper]: isWidget,
            [styles.highlightedServicesWrapper]: !isWidget,
          })}
        >
          {children}
        </div>
      )}
    >
      <div
        className={clsx(styles.highlightedServices, {
          [gridStyles.row]: !isOurWorkExperimentActive,
        })}
      >
        <Text as="h2" className={styles.title} type="mdHeader">
          {title}
        </Text>
        <Stack space="sm" className={styles.items}>
          <MenuGroupItems
            group={displayGroup}
            isActive
            channel={channel}
            isSuggestedGroup
            showDiscountRow={false}
            trackingGroupType={trackingGroupType}
          />
          <ShowMoreLink
            isMore={isMore}
            title={i18n('venue.menu.title.show-more', moreToShow)}
            onClick={() => {
              setShowMore(true);
            }}
          />
        </Stack>
      </div>
    </ConditionalWrapper>
  );
};

export const HighlightedServices = () => {
  const { pageData, isWidget } = useContext(Context);
  const { pageParameters, venue } = pageData;
  const { venue: venueData } = venue;
  const isChurned = venueData.status === VenueStatusType.CHURNED;
  const isSearch = hasSearchCriteria({ isChurned, pageParameters });
  const shouldShowHighlightedServices = !isWidget || (isWidget && isSearch);

  if (!shouldShowHighlightedServices) {
    return;
  }

  return (
    <HighlightedServicesComponent
      isChurned={isChurned}
      isWidget={isWidget}
      isSearch={isSearch}
    />
  );
};
