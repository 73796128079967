import React from 'react';
import {
  AccoladeOutput,
  extractVectorImageUri,
} from 'js/model/rainbow/browse-page/AccoladeOutput';
import styles from './VenueAccolades.module.css';

interface Props {
  accolades: AccoladeOutput[];
  i18n: (key: string, count?: number | string) => string;
}

export function VenueAccolades({ accolades, i18n }: Props): React.ReactElement {
  return (
    <div>
      <div className={styles.title}>
        {i18n('page.venue.section-titles.venue-awards')}
      </div>
      <div>
        {accolades.map(accolade => (
          <img
            key={accolade.id}
            className={styles.accolade}
            src={extractVectorImageUri(accolade)}
            alt={accolade.name}
          />
        ))}
      </div>
    </div>
  );
}
