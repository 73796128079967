import React from 'react';
import { PageSection } from 'js/components/PageSection';
import { Context } from 'js/components/LocaleWrapper';
import { ProductsAccoladesSection } from './ProductsAccoladesSection';

export function ProductsSection(props: {
  [key: string]: unknown;
}): React.ReactElement {
  return (
    <Context.Consumer>
      {value => (
        <PageSection id="products">
          <ProductsAccoladesSection
            products={value.pageData.venue.venue.productBrands}
            accolades={value.pageData.venue.venue.accolades}
            i18n={value.i18n}
            {...props}
          />
        </PageSection>
      )}
    </Context.Consumer>
  );
}
