import React, { useContext } from 'react';
import clsx from 'clsx';
import { ConnectedProps, connect } from 'react-redux';
import { Button, mParticleEventType, mParticleLogEvent } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { getVenueMenu } from 'js/redux-modules/venue-page';
import {
  selectMenuOption,
  unselectMenuOption,
} from 'js/redux-modules/venue-page/venue-menu';
import { VenueMenu } from '../VenueMenuSection/VenueMenu/MenuItem';
import styles from './SelectTreatmentButton.module.css';

const isServiceSelected = (
  venueMenu: VenueMenu,
  menuGroupId: number,
  menuItemId: string,
  optionId: string
): boolean => {
  const selectedGroup = venueMenu.selected[menuGroupId] || {};
  const selectedService = selectedGroup[menuItemId] || {};

  return !!selectedService[optionId];
};

const SelectTreatmentButtonComponent = ({
  venueMenu,
  selectMenuOption,
  unselectMenuOption,
  menuGroupId,
  menuItemId,
  menuOptionId,
  primaryTreatmentCategoryGroupId,
  onChange,
}: PropsFromRedux & {
  menuGroupId: number;
  menuItemId: string;
  menuOptionId: string;
  primaryTreatmentCategoryGroupId: number;
  onChange?: () => void;
}) => {
  const { channel, i18n } = useContext(Context);
  const isSelected = isServiceSelected(
    venueMenu,
    menuGroupId,
    menuItemId,
    menuOptionId
  );
  const buttonLabel = i18n(
    `venue.menu.labels.${isSelected ? 'selected' : 'select'}`
  );
  const onClick = () => {
    if (isSelected) {
      unselectMenuOption(
        menuGroupId,
        menuItemId,
        menuOptionId,
        primaryTreatmentCategoryGroupId
      );
    } else {
      mParticleLogEvent('treatment_selected', mParticleEventType.Other, {
        country: channel.country.countryCode,
        platform: 'web',
      });

      selectMenuOption(
        menuGroupId,
        menuItemId,
        menuOptionId,
        primaryTreatmentCategoryGroupId
      );
    }

    onChange?.();
  };

  return (
    <Button
      type="button"
      size="md"
      className={clsx({
        [styles.selected]: isSelected,
      })}
      data-cy="selectTreatmentButton"
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
};

const mapStateToProps = (state: any) => ({
  venueMenu: getVenueMenu(state),
});
const actions = {
  selectMenuOption,
  unselectMenuOption,
};
const connector = connect(mapStateToProps, actions);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const SelectTreatmentButton = connector(SelectTreatmentButtonComponent);
