import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  LazyImage,
  buttonStyles,
  mParticleEventType,
  mParticleLogEvent,
} from '@treatwell/ui';
import { updateUriWithOpenPopup } from 'js/helpers/uri-util';
import { EmployeePortfolioImage } from 'js/model/rainbow/ImageOutput';
import { Context } from 'js/components/LocaleWrapper';
import styles from './OurWorkImageButton.module.css';

export const OurWorkImageButton = ({
  image,
  onClick,
  buttonClassName,
  imageClassName,
  isLazy = false,
}: {
  image: EmployeePortfolioImage;
  onClick: (id: EmployeePortfolioImage['id']) => void;
  buttonClassName?: string;
  imageClassName?: string;
  isLazy?: boolean;
}) => {
  const { channel } = useContext(Context);
  const imageProps = {
    src: image.uris['800x1066'],
    alt: image.alt ?? '',
    className: clsx(styles.image, imageClassName),
  };

  return (
    <button
      key={image.id}
      className={clsx(buttonStyles.button, styles.root, buttonClassName)}
      onClick={() => {
        updateUriWithOpenPopup({
          portfolioImage: image.id,
        });

        mParticleLogEvent('portfolio_image_clicked', mParticleEventType.Other, {
          country: channel.country.countryCode,
          platform: 'web',
        });

        onClick(image.id);
      }}
    >
      {isLazy ? (
        <LazyImage {...imageProps} />
      ) : (
        <img {...imageProps} key={image.id} />
      )}
    </button>
  );
};
