/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { parseJson } from 'js/helpers/parse-json';
import { generateRuid } from 'js/helpers/ruid';
import { fetchCached, fetchHTTP, RequestData } from 'js/helpers/service';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { PaginationOutput } from 'js/model/rainbow/PaginationOutput';
import { VenueMenuItemOutput } from 'js/model/rainbow/venue/VenueMenuItemOutput';
import { ReviewOutput } from 'js/model/ReviewOutput';
import extractPageData from 'js/service/extract-page-data';
import { fetchFlipperClientFlag, flipperClientFlags } from 'server/flipper';
import { PageData } from 'server/routes/page/types';
import { getCookie } from '../../../rakuten/src/cookie';

export interface MenuItem {
  type: string;
  data: VenueMenuItemOutput;
  treatmentCategoryGroupId?: number;
}

interface VenueMenuGroup {
  id?: number;
  name: string;
  treatmentCategoryId?: number;
  groupItemType?: number;
  treatmentCategoryGroupId?: number;
  menuItems: MenuItem[];
}

const capitalizeWords = (menuString: string) =>
  menuString
    .split(/ /)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export async function fetchVenueData(
  name: string,
  state: RequestData,
  ruid: string
): Promise<Response> {
  const path = `/api/v1/page/venue/${name}`;

  return await fetchHTTP(state, path, ruid, true);
}

export async function fetchVenue(
  name: string,
  cookies: string | undefined,
  state: RequestData,
  ruid: string = generateRuid()
): Promise<PageData> {
  const flipperId = cookies ? getCookie('fe20-flipper-id', () => cookies) : '';
  const [venueData, flipper] = await Promise.all([
    fetchVenueData(name, state, ruid),

    fetchFlipperClientFlag(flipperClientFlags.VenueHeaderExperiment, ruid, {
      uniqueUserId: flipperId,
      customAttributes: {
        channelCode: state.channel.code,
      },
    }).catch(() => {
      return {
        result: {
          [flipperClientFlags.VenueHeaderExperiment]: false,
        },
      };
    }),
  ]);

  const extractedData = extractPageData(
    parseJson(await venueData.text(), ruid)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
  const venue = extractedData.venue;

  if (venue.menu) {
    venue.menu.menuGroups.forEach((menuGroup: VenueMenuGroup) => {
      // eslint-disable-next-line no-param-reassign
      menuGroup.id =
        menuGroup.id ||
        menuGroup.treatmentCategoryId ||
        menuGroup.groupItemType ||
        menuGroup.treatmentCategoryGroupId;

      // eslint-disable-next-line no-param-reassign
      menuGroup.name = capitalizeWords(menuGroup.name);
    });
  }

  if (extractedData.hasReviews) {
    venue.reviews = extractedData.venueReviews.reviews;
    venue.ratingHistogram = extractedData.venueReviews.ratingHistogram;
  }

  return {
    venue: extractedData,
    showVenueHeaderExperiment: flipper
      ? flipper.result[flipperClientFlags.VenueHeaderExperiment]
      : false,
  };
}

export const fetchVenuePortfolioImageMetadata = async (
  state: RequestData,
  venueId: number,
  images: ImageOutput[],
  ruid = generateRuid()
) => {
  const imageIds = images.map(img => img.id);
  const path = `/api/v1/venue/${venueId}/our-work/portfolio-images/by-image-id?imageIds=${imageIds.join(
    ','
  )}`;
  return await fetchCached(state, path, ruid);
};

export const fetchVenueReviews = (
  state: RequestData,
  venueId: number,
  treatmentCategoryId: number | null,
  menuItemId: number | null,
  page: number,
  size: number,
  ratings: string[] = [],
  includeRatingHistogram = false,
  excludeUnverifiedReviews = false,
  ruid = generateRuid()
): Promise<{
  reviews: ReviewOutput[];
  ratingHistogram: { rating: number; count: number }[];
  pagination: PaginationOutput;
} | null> => {
  const treatmentCategoryParam = treatmentCategoryId
    ? `treatmentCategoryIds=${treatmentCategoryId}`
    : '';
  let params = [
    `page=${page}`,
    `size=${size}`,
    `includeRatingHistogram=${includeRatingHistogram}`,
    `excludeUnverifiedReviews=${excludeUnverifiedReviews}`,
    treatmentCategoryParam,
  ];

  if (ratings.length) {
    params = params.concat([`ratings=${ratings.join(',')}`]);
  }
  const menuItem = menuItemId ? `menuItemId=${menuItemId}&` : '';
  const path = `/api/v1/venue/${venueId}/review?${menuItem}${params.join('&')}`;

  return fetchCached(state, path, ruid);
};
