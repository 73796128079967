import React, { useEffect } from 'react';
import {
  setCookie,
  getExpiryDate,
  storage,
  mParticleLogEvent,
  mParticleEventType,
} from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { ActiveVenue } from 'js/pages/VenuePage/ActiveVenue';
import { ClosedVenue } from 'js/pages/VenuePage/ClosedVenue';
import {
  Label,
  trackVenueHeaderExperiment,
} from 'js/components/Header/VenuePageHeader/tracking';
import { useTrackPageAndSessionView } from 'js/hooks/useTrackPageAndSessionView';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { LoginStatus } from 'js/model/rainbow/AccountDetailsOutput';

/*
TODO: this is likely to be the same for all activation events of an experiment.
Perhaps we can move the definition of this type directly to treatwell-ui?
*/
type ExperimentActivationEvent = {
  country: string;
  platform: 'web';
  'Experiment name': string;
  'Variant name': string;
  ['is_authenticated']: boolean;
};

const CLOSED_STATUSES = [
  'churned',
  'closed',
  'disabled',
  'temporarily_disabled',
];

const experimentName = 'ff-web-venue-our_work_above_menu';
const fallbackVariationId = 'none';

export function MarketplaceVenuePage(): React.ReactElement {
  const { channel, pageData, i18n, generateUri } = React.useContext(Context);
  const venuePage = pageData.venue;
  const venueStatus = venuePage.venue.status;
  const isClosed = CLOSED_STATUSES.includes(venueStatus);
  const isNotListedOnMarketplace =
    venueStatus === 'live' && venuePage.venue.listedOnMarketplace === false;
  const isChurned = venueStatus === 'churned';
  const isOurWorkExperimentActive = useFeatureIsOn(experimentName);
  const variationId = useFeatureValue(experimentName, fallbackVariationId);
  const hasOurWorkSection = venuePage.venue.employees.some(
    employee => employee.portfolioImages.length > 0
  );

  if (
    hasOurWorkSection &&
    isOurWorkExperimentActive &&
    variationId !== fallbackVariationId
  ) {
    mParticleLogEvent<ExperimentActivationEvent>(
      '$experiment_started',
      mParticleEventType.Other,
      {
        country: channel.country.countryCode,
        platform: 'web',
        'Experiment name': experimentName,
        'Variant name': variationId,
        is_authenticated:
          pageData.accountDetails.loginStatus ===
          LoginStatus.accountAuthenticated,
      }
    );
  }

  useTrackPageAndSessionView(venuePage.venue.id);

  useEffect(() => {
    const label = pageData.showVenueHeaderExperiment
      ? Label.ExperimentEnabled
      : Label.ExperimentDisabled;
    trackVenueHeaderExperiment(label);
  }, [pageData.showVenueHeaderExperiment]);

  useEffect(() => {
    const urlObject = new URL(location.href);
    const rwgToken = urlObject.searchParams.get('rwg_token');
    const venueId = pageData.venue.venue.id;

    if (!rwgToken) {
      return;
    }
    const rwgCookie = {
      venue_id: venueId,
      rwg_token: decodeURIComponent(rwgToken),
    };

    setCookie({
      name: 'rwg_cookie',
      value: JSON.stringify(rwgCookie),
      expires: getExpiryDate(30),
    });

    storage.session.setItem('rwg', venueId);

    urlObject.searchParams.delete('rwg_token');
    window.history.replaceState('', '', urlObject.toString());
  }, [pageData.venue.venue.id]);

  if (isClosed || isNotListedOnMarketplace) {
    return <ClosedVenue isChurned={isChurned} />;
  }
  return (
    <ActiveVenue i18n={i18n} pageData={pageData} generateUri={generateUri} />
  );
}
