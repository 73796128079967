/**
 * Groups an array of items into subarrays of a specified size.
 *
 * @param {T[]} items - The array of items to be grouped.
 * @param {number} groupSize - The size of each group.
 * @returns {T[][]} A new array where each element is an array containing `groupSize` elements from the original array.
 *
 * @example
 * // Group an array of numbers into subarrays of 3
 * const numbers = [1, 2, 3, 4, 5, 6, 7];
 * const result = getGroupedItems(numbers, 3);
 * // result is [[1, 2, 3], [4, 5, 6], [7]]
 */
export function getGroupedItems<T>(items: T[], groupSize: number): T[][] {
  return items.reduce<T[][]>((groups, _, index) => {
    if (index % groupSize === 0) {
      groups.push(items.slice(index, index + groupSize));
    }

    return groups;
  }, []);
}
