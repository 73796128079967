import React from 'react';
import {
  IconChevronLeft,
  Inline,
  Stack,
  Viewport,
  useDevice,
} from '@treatwell/ui';
import { useTranslation } from 'react-i18next';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { CmsCommon } from 'js/model/cms/cms-common';
import { CmsVenue } from 'js/model/cms/cms-venue';
import { ImageGallery } from 'js/pages/VenuePage/VenueHeader/ImageGallery/ImageGallery';
import { CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { Divider } from 'js/pages/VenuePage/Divider';
import {
  NavigateAnchorLink,
  generateNavigationTextAndLink,
} from 'js/components/Header/VenuePageHeader/VenuePageHeaderMobileContainer';
import { Context } from 'js/components/LocaleWrapper';
import { EmployeePortfolioImage } from 'js/model/rainbow/ImageOutput';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Details1 } from './Details1';
import { Details2 } from './Details2';
import { OurWorkExperiment } from '../Experiments/OurWork/OurWorkExperiment';
import { HighlightedServices } from '../VenueMenuSection/VenueMenu/HighlightedServices/HighlightedServices';
import styles from './VenueHeader.module.css';

export type Props = {
  venue: VenueOutput;
  channelData: ChannelOutput;
  isActiveVenue?: boolean;
  cmsVenue: CmsVenue;
  cmsCommon: CmsCommon;
  cmsChainTemplate: CmsVenuePage['page']['venue']['details']['chain'];
  cmsHeaderNavigation: CmsVenuePage['page']['venue']['header-navigation'];
  hasReviews: boolean;
  portfolioImages?: EmployeePortfolioImage[];
};

export function VenueHeader(props: Props): React.ReactElement {
  const {
    venue,
    cmsVenue,
    cmsChainTemplate,
    cmsCommon,
    channelData,
    cmsHeaderNavigation,
    isActiveVenue = true,
    hasReviews,
    portfolioImages = [],
  } = props;
  const { i18n, pageData } = React.useContext(Context);
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const {
    navigateLink,
    navigateText,
    onNavigateLinkClick,
  } = generateNavigationTextAndLink({
    pageData,
    t,
    i18n,
  });
  const showBackButton = pageData.showVenueHeaderExperiment;

  const discountTypes = venue.priceRange
    ? venue.priceRange.yieldDiscountTypes
    : [];

  const details1 = (
    <div className={styles.details}>
      <Details1
        bttLabels={cmsCommon.labels}
        reviewCountTemplate={cmsVenue['reviews-template']}
        venue={venue}
        cmsHeaderNavigation={cmsHeaderNavigation}
        isActiveVenue={isActiveVenue}
        hasReviews={hasReviews}
      />
    </div>
  );

  const details2 = (
    <div className={styles.details}>
      <Details2
        address={venue.location.address}
        chain={venue.chain}
        discountTypes={discountTypes}
        venueType={venue.type}
        venueSubtypes={venue.subTypes}
        cmsCommon={cmsCommon}
        cmsVenue={cmsVenue}
        cmsChainTemplate={cmsChainTemplate}
        openingHours={venue.openingHours}
        countryCode={channelData.country.countryCode}
        isActiveVenue={isActiveVenue}
      />
    </div>
  );

  const isOurWorkExperimentActive =
    useFeatureValue('ff-web-venue-our_work_above_menu', 'none') === 'variant-1';
  const hasPortfolioImages = portfolioImages.length > 0;

  return (
    <div className={styles.container}>
      <Viewport serverRender={isMobile} device={['mobile', 'tablet']}>
        <Stack space="md">
          <ImageGallery isMobile />
          <Stack space="sm">
            {details1}
            {details2}
          </Stack>
          <Divider />
        </Stack>
        {isOurWorkExperimentActive &&
          (hasPortfolioImages ? (
            <>
              <OurWorkExperiment images={portfolioImages} />
              <HighlightedServices />
            </>
          ) : (
            <HighlightedServices />
          ))}
      </Viewport>

      <Viewport serverRender={!isMobile} device="desktop">
        <Stack space="lg">
          {showBackButton && (
            <NavigateAnchorLink
              navigateLink={navigateLink}
              navigateText={navigateText}
              onNavigateLinkClick={onNavigateLinkClick}
              icon={<IconChevronLeft size={16} />}
            />
          )}
          {details1}
          <ImageGallery />
          {isOurWorkExperimentActive && hasPortfolioImages ? (
            <Inline align="start" space="xl">
              <div className={styles.experimentContainer}>
                <Stack space="lg">
                  {details2}
                  <Divider />
                </Stack>
                <HighlightedServices />
              </div>
              <OurWorkExperiment images={portfolioImages} />
            </Inline>
          ) : (
            <>
              {details2}
              <Divider />
              {isOurWorkExperimentActive && <HighlightedServices />}
            </>
          )}
        </Stack>
      </Viewport>
    </div>
  );
}
