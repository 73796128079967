import React from 'react';

export const ConditionalWrapper = ({
  condition,
  render,
  children,
}: {
  condition: boolean;
  render: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}) => (condition ? render(children) : <>{children}</>);
